<template>
    <div v-if="loggedIn && user?.impersonated">
        <div class="sudosu-frame" style="bottom: 0; width: 100%; margin: 0 0 0 5px; padding: 10px 40px 10px 50px; color: white; line-height: 20px">
            <i class="fa fa-warning"></i>
            You are currently logged in as {{ user?.username }}. If this is unintentional or you no longer need to use this identity, you can stop
            impersonating this user by clicking
            <VJoyLink href="#" @click.prevent="handleUnSudosu">here</VJoyLink>
            .
            <span v-if="message">{{ message }}</span>
        </div>
        <div class="sudosu-frame sudosu-side" style="left: 0"></div>
        <div class="sudosu-frame sudosu-side" style="right: 0"></div>
        <div class="sudosu-frame" style="left: 0; top: 0; height: 5px; width: 100%"></div>
    </div>
</template>

<script setup lang="ts">
    import {useAuth} from '#imports';
    import {storeToRefs} from 'pinia';
    import {FetchError} from 'ofetch';
    import {ref} from 'vue';
    import {VJoyLink} from '@maltjoy/core-vue';

    const auth = useAuth();
    const {user, loggedIn} = storeToRefs(auth);
    const message = ref('');

    async function handleUnSudosu() {
        try {
            await auth.unsudosu();
            window.location.reload();
        } catch (error) {
            if (error instanceof FetchError) {
                message.value = error.message || error.toString();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sudosu-side {
        top: 0;
        height: 100%;
        width: 5px;
    }
    .sudosu-frame {
        position: fixed;
        z-index: 1100;
        background: red;
    }
</style>
